import React, { Component } from "react"
import PageWrapper from "../components/PageWrapper/PageWrapper"
import SEO from "../components/seo"
import Publbox from "../components/Pages/Projects/Publbox/Publbox"

class PublboxPage extends Component {
  render() {
    return (
      <PageWrapper>
        <SEO
          title='Publbox'
          description='Social Media Management platform integrated with multiple social networks that allows you to create, post and track content for your business account. Service features powerful online image editor and educational system.'
        />
        <Publbox/>
      </PageWrapper>
    )
  }
}

export default PublboxPage

import * as React from 'react';
import Header from '../../../Header/Header';
import BlockHeader from '../../../UI/BlockHeader/BlockHeader';
import Icon from '../../../UI/Icon/Icon';
import ProjectAppLinks from '../../../UI/ProjectAppLinks/ProjectAppLinks';
import ProjectBlock from '../../../UI/ProjectBlock/ProjectBlock';
import ProjectFooter from '../../../UI/ProjectFooter/ProjectFooter';
import ProjectGoals from '../../../UI/ProjectGoals/ProjectGoals';
import ProjectIntro from '../../../UI/ProjectIntro/ProjectIntro';

import './Publbox.sass';

const challenges = [
  'Supports 7 major social networks',
  'Online editor for images with templates',
  'Media Post designing and scheduling tools',
  'Machine learning for content generation',
  'Sophisticated analytics tools',
];

class Publbox extends React.Component {

  render() {

    const info = [
      { label : 'Client', value : 'PublBox' },
      { label : 'Year', value : '2016' },
      { label : 'Category', value : 'Web App / Mobile App / Website' },
      {
        label     : 'Website Url',
        value     : 'https://publbox.com/en/',
        linkLabel : 'publbox.com',
        isLink    : true,
      },
    ];

    return (
      <main className='publbox'>
        <Header/>
        <ProjectIntro title='PublBox'
                      description='Social Media Management platform integrated with multiple social networks that allows you to create, post and track content for your business account. Service features powerful online image editor and educational system.'
                      heading='heading-case'
                      info={info}>
          <img src='../../../../images/projects/publbox/heading.png'
               alt='Screenshots: PublBox image editor'/>
        </ProjectIntro>
        <ProjectGoals challenges={challenges}/>


        <div className='project-block max-width publbox__posts -image-first'>
          <BlockHeader
            title='Post Designer'
            orientation='vertical'
            subtitle='Post Designer is built to work with templates that automatically re-shape your content for each social network. You can add text content, images, videos and then schedule or publish it right away. Everything can be done on the same page!'
          >
            <img src='../../../../images/projects/publbox/create-post-header-part.png' alt='Screenshots: PublBox create post header' className='header-part'/>
            <Icon name='heading-num1' className='heading' raw/>
          </BlockHeader>
          <div className='project-block__content'>
            <img src='../../../../images/projects/publbox/create-post.png' alt='Screenshots: PublBox create post'/>
          </div>
        </div>
        <ProjectBlock className='publbox__navigator'
                      heading='heading-num2'
                      centeredContent
                      title='Content Navigator'
                      subtitle='Content Navigator is using machine learning algorithms to find the best content strategy according to your business needs. You will get a better schedule for your posts in addition to a solid advice on the content.'
                      image={{
                        src : '../../../../images/projects/publbox/content-navigator.png',
                        alt : 'Screenshots: PublBox content navigator',
                      }}/>
        <div className='publbox__navigator-desktop max-width'>
          <img src='../../../../images/projects/publbox/content-navigator-desktop.png'
               alt='Screenshots: PublBox content navigator, desktop version'/>
        </div>
        <ProjectBlock className='publbox__calendar max-width'
                      heading='heading-num3'
                      title='Smart Calendar'
                      subtitle='Plan your activity in social networks, see all your scheduled post at a glance and thanks to automatic friendly reminders you will never miss a single date.'
                      centered={true}
                      image={{
                        src : '../../../../images/projects/publbox/calendar.png',
                        alt : 'Screenshots: PublBox calendar reminder',
                      }}/>
        <ProjectBlock className='publbox__dashboard'
                      heading='heading-num4'
                      title='Dashboard'
                      subtitle='Publbox collects every bit of important information from your accounts and presents it in a convenient way so you can review the audience growth, involvement, and the most popular posts.'
                      isImageFirst
                      centeredContent
                      overlappedHeading
                      titleGap
                      image={{
                        src : '../../../../images/projects/publbox/dashboard.png',
                        alt : 'Screenshots: PublBox dashboard with graphs',
                      }}/>
        <div className='publbox__dashboard-posts max-width'>
          <img src='../../../../images/projects/publbox/popular-posts.png'
               alt='Screenshots: PublBox dashboard with most popular posts'/>
        </div>
        <ProjectBlock className='publbox__design'
                      heading='heading-num5'
                      title='Design Editor'
                      centeredContent
                      overlappedHeading
                      titleGap
                      subtitle='A custom built image editor helps you design stunning images for your posts. You can build them from scratch or use one of the beautiful templates built for every occasion. You can also design and publish covers and brand pictures to your social pages.'
                      isImageFirst={true}
                      image={{
                        src : '../../../../images/projects/publbox/design-editor.png',
                        alt : 'Illustration: PublBox design editor on big Mac',
                      }}/>
        <ProjectBlock className='publbox__education'
                      heading='heading-num6'
                      title='Marketing Education'
                      subtitle='In order to improve your Social Media Marketing skills you can take a special course of more than 100 lessons explaining the basics of working with social media networks including strategy, promotion, and building unique content.'
                      image={{
                        src : '../../../../images/projects/publbox/education.png',
                        alt : 'Screenshots: PublBox education page',
                      }}/>
        <ProjectBlock className='publbox__mobile-app'
                      heading='heading-num7'
                      title='Mobile App'
                      subtitle='Companion mobile app allows you to review posts, publication schedules and access complete analytics reports.'
                      isImageFirst={true}
                      image={{
                        src : '../../../../images/projects/publbox/mobile-app.png',
                        alt : 'Screenshots: PublBox on mobile devices',
                      }}/>
        {/*<ProjectAppLinks icon='publbox'*/}
        {/*                 title='PublBox'*/}
        {/*                 message='Get The Best Social Media Planning and Content Creation Suite. Available on the AppStore and GooglePlay.'*/}
        {/*                 appStoreLink='https://apps.apple.com/us/app/publbox-social-media-tools/id1183440684'*/}
        {/*                 googlePlayLink='https://play.google.com/store/apps/details?id=com.publbox.app&hl=en'*/}
        {/*                 className='publbox__links'*/}
        {/*/>*/}
        <ProjectFooter title='IQ Business Center'
                       className='publbox__footer'
                       link='#'
                       commingSoon
                       imageSrc='../../../../images/projects/iq-bc-footer.png'
                       imageAlt='Image: polygonal sphere with links'
        />
      </main>
    );
  }
}

export default Publbox;
